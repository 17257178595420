.products {
	background-color: #030303;
	padding: 100px 0;

	// .products__swiper
	&__swiper {
		position: relative;
		display: flex;
		align-items: flex-start;
		padding-bottom: 30px;
		margin: 50px clamp(2.5rem, 1.18rem + 5.63vw, 3.8rem) 0
			clamp(2.5rem, 1.18rem + 5.63vw, 3.8rem);
	}

	// .products__container
	&__container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;
		background-color: #343444;
		border-radius: 25px;
		padding: 10px 25px 50px 25px;
	}

	// .products__slide
	&__slide {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	// .products__img
	&__img {
		margin-top: 20px;
		width: 200px;
		border-radius: 25px;
	}

	// .products__name
	&__name {
		font-size: 14px;
		font-weight: 700;
		width: 200px;
	}
}

.products2 {
	position: relative;
	background-color: #232237;
	padding: 100px 0;
	display: flex;
	flex-direction: column;
	gap: 30px;

	&__cards {
		display: flex;
		flex-wrap: wrap;
		gap: 60px;
		justify-content: center;
		margin-top: 30px;
	}

	&__card {
		display: flex;
		flex-direction: column;
		gap: 10px;
		background-color: #343444;
		border-radius: 25px;
		padding-bottom: 20px;
		//flex: 1 0 33%;
	}

	&__image {
	}

	&__name {
		margin-left: 10px;
		font-size: 18px;
		font-weight: 600;
	}

	&__price {
		margin-left: 10px;
		font-size: 18px;
		font-weight: 600;
	}
}

.swiper-pagination {
	bottom: 0 !important;

	&-bullet {
		background-color: #ffffff;

		&-active {
			background-color: #6345ed;
		}
	}
}
