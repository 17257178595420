.header {
  position: relative;
  background-color: #343444;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px;
  gap: 30px;
}
@media (max-width: 535px) {
  .header {
    flex-direction: column;
  }
}
.header__left {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 535px) {
  .header__left {
    flex-direction: column;
  }
}
.header__text {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  font-size: 14px;
}
.header__right {
  display: flex;
  align-items: center;
  gap: 5px;
}
.header__link img {
  cursor: pointer;
}

