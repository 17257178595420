.header {
	position: relative;
	background-color: #343444;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 35px;
	gap: 30px;
	@media (max-width: 535px) {
		flex-direction: column;
	}

	// .header__left
	&__left {
		display: flex;
		align-items: center;
		gap: 30px;
		@media (max-width: 535px) {
			flex-direction: column;
		}
	}

	// .header__text
	&__text {
		display: flex;
		flex-direction: row-reverse;
		gap: 10px;
		font-size: 14px;
	}

	// .header__right
	&__right {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	// .header__link
	&__link {
		img {
			cursor: pointer;
		}
	}
}
