.sellers {
  position: relative;
  background-color: #030303;
  padding: 100px 0;
}
.sellers__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: clamp(2.5rem, 1.18rem + 5.63vw, 6.25rem);
  margin-right: clamp(2.5rem, 1.18rem + 5.63vw, 6.25rem);
  gap: 40px;
}
.sellers__card {
  border: 1px solid #231a4b;
  padding: 35px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.sellers__seller-image {
  object-fit: cover;
}
.sellers__seller-name {
  font-size: 16px;
  font-weight: 600;
}
.sellers__seller-button {
  margin-top: 20px;
  padding: 10px 25px;
  border: 3px solid #ffffff;
  border-radius: 25px;
}

