.products {
  background-color: #030303;
  padding: 100px 0;
}
.products__swiper {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
  margin: 50px clamp(2.5rem, 1.18rem + 5.63vw, 3.8rem) 0 clamp(2.5rem, 1.18rem + 5.63vw, 3.8rem);
}
.products__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background-color: #343444;
  border-radius: 25px;
  padding: 10px 25px 50px 25px;
}
.products__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.products__img {
  margin-top: 20px;
  width: 200px;
  border-radius: 25px;
}
.products__name {
  font-size: 14px;
  font-weight: 700;
  width: 200px;
}

.products2 {
  position: relative;
  background-color: #232237;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.products2__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: center;
  margin-top: 30px;
}
.products2__card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #343444;
  border-radius: 25px;
  padding-bottom: 20px;
}
.products2__name {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
}
.products2__price {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
}

.swiper-pagination {
  bottom: 0 !important;
}
.swiper-pagination-bullet {
  background-color: #ffffff;
}
.swiper-pagination-bullet-active {
  background-color: #6345ed;
}

