.creators {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: #0d0d12;
  padding: 100px 0;
}
.creators__cards {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
  flex-wrap: wrap;
}
.creators__card {
  background-color: #030303;
  border-radius: 25px;
  border: 1px solid #716951;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.creators__desc {
  font-size: 18px;
  font-weight: 600;
}
.creators__imgs {
  display: grid;
  grid-gap: 10px 10px;
  grid-template-areas: "big big small small2" "big big long-small long-small" ". . . .";
  place-items: center;
}
@media (max-width: 535px) {
  .creators__imgs {
    grid-template-areas: "big big" "big big" "small small2" "long-small long-small" ". .";
  }
}
.creators__imgs img:nth-child(1) {
  grid-area: big;
}
.creators__imgs img:nth-child(2) {
  grid-area: small;
}
.creators__imgs img:nth-child(3) {
  grid-area: small2;
}
.creators__imgs img:nth-child(4) {
  grid-area: long-small;
}
.creators__img {
  -o-object-fit: cover;
     object-fit: cover;
}
.creators__creator {
  display: flex;
  align-items: center;
  gap: 10px;
}
.creators__creator-img {
  width: 48px;
  height: 48px;
}
.creators__creator-name {
  font-size: 16px;
  font-weight: 600;
}
.creators__creator-desc {
  font-size: 14px;
  font-weight: 600;
  color: #90a0a8;
}